<template>
  <div class="text-center">
    <v-overlay :value="toggle" :absolute="absolute">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
export default {
  props: ["isVisible"],
  data: () => ({
    overlay: false,
    absolute: true
  }),
  computed: {
    toggle: function() {
      if (this.isVisible === undefined || !this.isVisible) {
        return false;
      } else {
        return true;
      }
    }
  }
};
</script>
