export default {
  getTimeDifference(auftragTimestamp, antragsTyp, store) {
    let max_antrag_lifetime_in_days = undefined;
    //let max_antrag_lifetime_in_hours=undefined;
    let max_antrag_lifetime_in_minutes = undefined;
    if (antragsTyp === "normal") {
      max_antrag_lifetime_in_days =
        store.getters.getConstAntragTypNormalLaufzeitInTagen;
      //max_antrag_lifetime_in_hours=max_antrag_lifetime_in_days*24;
      max_antrag_lifetime_in_minutes = max_antrag_lifetime_in_days * 24 * 60;
    } else if (antragsTyp === "express") {
      max_antrag_lifetime_in_days =
        store.getters.getConstAntragTypExpressLaufzeitInTagen;
      //max_antrag_lifetime_in_hours=max_antrag_lifetime_in_days*24;
      max_antrag_lifetime_in_minutes = max_antrag_lifetime_in_days * 24 * 60;
    } else {
      alert("unknown AntragTyp");
    }
    let currentTimestamp = new Date().getTime();
    let difference = currentTimestamp - auftragTimestamp;
    let minutesDifference = Math.floor(difference / 1000 / 60);

    // we have some "positive" time
    if (minutesDifference < max_antrag_lifetime_in_minutes) {
      const absDiff = max_antrag_lifetime_in_minutes - minutesDifference;
      let td = 0;
      let hd = 0;
      let hm = 0;
      // over 24 hours
      if (absDiff >= 24 * 60) {
        td = Math.floor(absDiff / (24 * 60));
        hd = Math.floor((absDiff - td * 24 * 60) / 60);
        hm = Math.floor(absDiff - td * 24 * 60 - hd * 60);
        return [
          "noch " +
            td +
            (td < 2 ? " Tag, " : " Tage, ") +
            hd +
            " Std und " +
            hm +
            " Min",
          absDiff
        ];
      } else if (max_antrag_lifetime_in_minutes - minutesDifference >= 60) {
        hd = Math.floor(absDiff / 60);
        hm = Math.floor(absDiff - hd * 60);
        return ["noch " + hd + " Std und " + hm + " Min", absDiff];
      } else {
        hm = absDiff;
        return ["noch " + hm + " Min", absDiff];
      }
      //console.log(max_antrag_lifetime_in_hours)
    } else {
      //console.log("NEGATIV")
      //console.log("DIff to Currrent:"+minutesDifference)
      const absDiff = minutesDifference - max_antrag_lifetime_in_minutes;
      //console.log("absDIFF:"+absDiff)
      let td = 0;
      let hd = 0;
      let hm = 0;
      if (absDiff < 60) {
        return ["fällig seit " + hm + " Min", absDiff * -1];
      } else if (absDiff < 24 * 60) {
        hd = Math.floor(absDiff / 60);
        hm = absDiff - hd * 60;
        return ["fällig seit " + hd + " Std und " + hm + " Min", absDiff * -1];
      } else {
        td = Math.floor(absDiff / 60 / 24);
        hd = Math.floor((absDiff - td * 24 * 60) / 60);
        hm = absDiff - td * 24 * 60 - hd * 60;
        return [
          "fällig seit " +
            td +
            (td === 1 ? " Tag, " : " Tagen, ") +
            hd +
            " Std und " +
            hm +
            " Min",
          absDiff * -1
        ];
      }
    }
  }
};
