import axios from "axios";

export default {
  async getSignedDownloadLink(store, bucket, key) {
    const tUrl =
      store.getters.getAPIContext.endpoint_get_signed_download_url +
      "?bucket=" +
      encodeURIComponent(bucket) +
      "&key=" +
      encodeURIComponent(key);
    let tokenId = store.getters.getAuthBearer;
    let header = {
      Authorization: "Bearer " + tokenId,
      "Content-Type": "application/json"
    };
    try {
      const response = await axios.get(tUrl, { headers: header });
      if (response.status === 401 || response.status === 403) {
        await store.context.dispatch("logout");
      } else if (response.status === 200) {
        return response.data;
      } else {
        alert(
          "Bei Kommunikation mit dem Server ist ein Fehler aufretten(code: JS-DLS-GSDL-CODE[" +
            response.status +
            "])"
        );
      }
    } catch (error) {
      if (error.message.indexOf("code 400") !== -1) {
        alert(
          "Bei Kommunikation mit dem Server ist ein Fehler aufretten(code: JS-DLS-GSDL-400)"
        );
      } else if (error.message === "Network Error") {
        alert(
          "Bei Kommunikation mit dem Server ist ein Fehler aufretten(code: JS-DLS-GSDL-NE)"
        );
      } else {
        alert(
          "Bei Kommunikation mit dem Server ist ein Fehler aufretten(code: JS-DLS-GSDL-UN)"
        );
      }
      await store.context.dispatch("logout");
    }
  }
};
