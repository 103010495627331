<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="410">
      <v-card>
        <v-card-title class="headline">
          Eingaben wirklich zurücksetzen?
        </v-card-title>
        <!--                <v-card-text>Let Google help apps determine location. This means sending anonymous location data to Google, even when no apps are running.</v-card-text>-->
        <v-card-actions class="mt-10">
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="closeDialog(false)">
            Nein
          </v-btn>
          <v-btn color="red darken-1" text @click="closeDialog(true)">
            Ja
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: {
    dialogOpened: {
      required: true
    }
  },
  data() {
    return {
      dialog: false
    };
  },
  mounted() {
    this.dialog = this.dialogOpened;
  },
  methods: {
    closeDialog(currentState) {
      this.dialog = false;
      // alert(currentState);
      this.$emit("update-reset-dialog-state", currentState);
    }
  },
  computed: {
    openDialog(pro) {
      console.log(pro);
      return this.dialog;
    }
  }
};
</script>
