<template>
  <v-dialog
    v-model="dialog"
    light
    max-width="90%"
    scrollable
    width="90%"
    @click:outside="exitDialog"
  >
    <v-card v-if="readUIVisibility">
      <v-toolbar color="teal" dark>
        <v-toolbar-title>
          Textbausteine
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="exitDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-container style="height: 100%; overflow:hidden">
        <v-row class="flex-nowrap" style="overflow:hidden; height:100%">
          <v-col
            class="pa-0 pl-2"
            cols="4"
            style="height:100%;overflow:hidden;display: flex; flex-direction: column"
          >
            <v-divider></v-divider>
            <v-card-text style="height: 100%; overflow:auto">
              <v-text-field
                v-model="search"
                class="mt-2"
                clearable
                dense
                flat
                hide-details
                label="Suche..."
                outlined
                prepend-inner-icon="mdi-database-search"
                scrollable
                solo
                @click:clear="clearSearch"
              ></v-text-field>
              <v-list>
                <v-subheader class="mt-1" style="max-height: 20px;"
                  >Kategorien
                </v-subheader>
                <!--<template v-for="(item, index) in filteredItems">-->
                <v-list-group
                  v-for="item in filteredItems"
                  :key="item.title"
                  v-model="item.active"
                  :prepend-icon="item.action"
                  class="bordered-dialog-list-group"
                  no-action
                >
                  <template v-slot:activator>
                    <v-list-item-content>
                      <v-list-item-title
                        v-text="item.title"
                      ></v-list-item-title>
                    </v-list-item-content>
                  </template>
                  <v-list-item-group class="pl-10">
                    <v-list-item
                      v-for="(child, i) in item.items"
                      :key="i"
                      sub-group
                      @click="setText(child, item.title)"
                    >
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ toPlainText(child.eltitle) }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          {{ toPlainText(child.text) }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list-group>
              </v-list>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="mt-2" style="width: 100%">
              <v-row no-gutters>
                <v-col
                  class="text-left d-inline-flex justify-space-between"
                  cols="12"
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        color="#f4511e"
                        dark
                        fab
                        @click="changeVisibility"
                      >
                        <v-icon dark>mdi-plus</v-icon>
                      </v-btn>
                    </template>
                    <span>Erstellen</span>
                  </v-tooltip>
                  <v-card class="elevation-0 rounded-0">
                    <v-btn
                      :disabled="!editedBlockModelSelectButtonEnabled"
                      class="ml-2 mt-2"
                      color="primary"
                      large
                      @click="closeDialog"
                    >
                      Auswählen
                    </v-btn>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-col>
          <v-col v-if="this.showBlockText" cols="8" style="height:100%">
            <v-row
              class="fill-height flex-nowrap"
              style="min-width: 720px; height:100%"
            >
              <v-col class="fill-height ma-0 pa-0 ml-2" style="max-width: 2px">
                <v-divider vertical></v-divider>
              </v-col>
              <v-col
                class="fill-height pb-0 pr-5"
                cols="12"
                style="display: flex;flex-direction: column;"
              >
                <v-row style="max-height:5em">
                  <v-col>
                    <v-text-field
                      v-model="editedBlockModelTitle"
                      hide-details
                      label="Überschrift"
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row
                  class="edited-box-row"
                  style="min-height: 35%;overflow-x: hidden; overflow-y: auto;"
                >
                  <v-col>
                    <v-textarea
                      v-model="editedBlockModel"
                      class="fill-height"
                      dense
                      label="Baustein Text"
                      name="input-7-4"
                      outlined
                    ></v-textarea>
                  </v-col>
                </v-row>
                <v-row style="max-height:3em">
                  <v-col class="text-right pb-0 pt-0">
                    <v-btn
                      :disabled="editedBlockModelUpdateButtonEnabled"
                      class="mr-2"
                      color="primary"
                      large
                      @click="updateTextBlock"
                      >Aktualisieren
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <v-card v-if="!readUIVisibility">
      <v-toolbar color="teal" dark>
        <v-toolbar-title>
          Neuen Textbaustein erstellen
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="exitDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-divider></v-divider>
      <v-card-text style="height: 70%">
        <v-combobox
          v-model="categoriesModel"
          :items="categories"
          class="mt-4"
          dense
          label="Kategorie"
          outlined
          placeholder="Kategorie auswählen oder neue schreiben"
        ></v-combobox>
        <v-spacer style="height: 20px;"></v-spacer>
        <v-text-field
          v-model="descriptionTitle"
          :rules="rules"
          counter="50"
          label="Bezeichnung"
          outlined
          placeholder="Bezeichnung"
        ></v-text-field>
        <v-spacer style="height: 30px;"></v-spacer>
        <v-textarea
          v-model="textBlockModel"
          dense
          label="Baustein Text"
          name="input-7-4"
          outlined
          placeholder="Text...."
          value=""
        ></v-textarea>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-row no-gutters>
          <v-col class="text-right">
            <div class="my-2 mr-0">
              <v-btn
                class="mx-2"
                color="primary"
                @click="persistTextBlock(false)"
              >
                Speichern und weiteren Eintrag erstellen
              </v-btn>

              <v-btn
                class="mx-2"
                color="primary"
                @click="persistTextBlock(true)"
              >
                Speichern und Schließen
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
    <v-snackbar v-model="snackbar" :color="snackColor" :timeout="timeout">
      {{ snackbartext }}

      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" color="white" text @click="snackbar = false">
          X
        </v-btn>
      </template>
    </v-snackbar>
  </v-dialog>
</template>

<script>
/* TODO: reduce v-list-item and groups height */
import { mapGetters } from "vuex";

export default {
  name: "Textbausteine",
  methods: {
    exitDialog() {
      this.$emit("close-dialog", undefined);
      this.dialog = false;
      this.selectedItem = null;
    },
    closeDialog() {
      this.$emit("close-dialog", this.selectedText);
      this.dialog = false;
      this.child = null;
      this.selectedItem = null;
      this.selectedTextTitle = null;
      this.editedBlockModel = null;
      this.editedBlockModelTitle = null;
      this.editedBlockModelId = null;
      this.editedBlockModelCategory = null;
    },

    setText(child, category) {
      this.child = child;
      this.selectedText = this.toPlainText(child.text.trim());
      this.selectedTextTitle = this.toPlainText(child.eltitle.trim());
      this.customwidth = "initial";
      this.editedBlockModel = this.selectedText;
      this.editedBlockModelTitle = this.toPlainText(child.eltitle.trim());
      this.editedBlockModelId = child.id;
      this.editedBlockModelCategory = category.trim();
      this.showBlockText = true;
      this.editedBlockModelUpdateButtonEnabled = true;
      this.editedBlockModelSelectButtonEnabled = true;
    },

    clearSearch() {
      this.search = "";
    },

    toPlainText(html) {
      const scratchDiv = document.createElement("div");
      scratchDiv.innerHTML = html;
      return scratchDiv.textContent;
    },

    async updateTextBlock() {
      //console.log('Textbaustein:'+this.editedBlockModel);
      //console.log('Bausteinbezeichnung:'+this.editedBlockModelTitle);
      if (
        this.editedBlockModel === null ||
        this.editedBlockModel.trim() === ""
      ) {
        alert("Textbaustein darf nicht leer sein!");
        return;
      }
      if (
        this.editedBlockModelTitle === null ||
        this.editedBlockModelTitle.trim() === ""
      ) {
        alert("Bausteinbezeichnung darf nicht leer sein!");
        return;
      }
      if (!/^[a-z0-9\s_\-äüßö]{1,50}$/gi.test(this.editedBlockModelTitle)) {
        alert(
          "Bausteinbezeichnung beinhaltet ungültige Zeichen oder ist zu lang(max. 50 Zeichen)!"
        );
        return;
      }

      await this.$store.dispatch("updateTextBlockTemplate", {
        id: this.editedBlockModelId,
        category: this.editedBlockModelCategory.trim(),
        title: this.editedBlockModelTitle.trim(),
        text: this.editedBlockModel.trim()
      });
      this.items = this.$store.getters.getTextBlockTemplates;

      this.selectedText = this.editedBlockModel.trim();
      this.selectedTextTitle = this.editedBlockModelTitle.trim();
      this.editedBlockModelUpdateButtonEnabled = true;
      this.editedBlockModelSelectButtonEnabled = true;

      //Confirmation

      this.snackbar = true;
      this.snackbartext = "Textbaustein aktualisiert.";
      this.snackColor = "success";
    },
    async persistTextBlock(addMoreBausteine) {
      if (this.categoriesModel === null || this.categoriesModel.trim() === "") {
        alert("Kategorie darf nicht leer sein!");
        return;
      }
      if (this.textBlockModel === null || this.textBlockModel.trim() === "") {
        alert("Textbaustein darf nicht leer sein!");
        return;
      }
      if (
        this.descriptionTitle === null ||
        this.descriptionTitle.trim() === ""
      ) {
        alert("Bausteinbezeichnung darf nicht leer sein!");
        return;
      }
      if (!/^[a-z0-9\s_\-äüßö]{1,50}$/gi.test(this.categoriesModel)) {
        alert(
          "Kategorie beinhaltet ungültige Zeichen oder ist zu lang(max. 50 Zeichen)!"
        );
        return;
      }
      if (!/^[a-z0-9\s_\-äüßö]{1,50}$/gi.test(this.descriptionTitle)) {
        alert(
          "Bausteinbezeichnung beinhaltet ungültige Zeichen oder ist zu lang(max. 50 Zeichen)!"
        );
        return;
      }

      //alert('gespeichert: cat['+this.categoriesModel+'], textblock['+this.textBlockModel+'], title['+this.descriptionTitle+']');
      await this.$store.dispatch("pushNewTextBlockTemplate", {
        category: this.categoriesModel.trim(),
        title: this.descriptionTitle.trim(),
        text: this.textBlockModel.trim()
      });
      if (addMoreBausteine == true) this.changeVisibility();
      this.items = this.$store.getters.getTextBlockTemplates;
      this.updateTextbausteinCategories();

      this.snackbar = true;
      this.snackbartext = "Textbaustein hinzugefügt.";
      this.snackColor = "success";

      this.clearDialog();
    },
    changeVisibility() {
      this.readUIVisibility = !this.readUIVisibility;
    },
    clearDialog() {
      this.categoriesModel = "";
      this.textBlockModel = "";
      this.descriptionTitle = "";
    },
    updateTextbausteinCategories() {
      this.categories = this.getTextbausteinCategories;
    },
    async reloadTextBlockTemplates() {
      this.items = await this.$store.dispatch("initTextBlockTemplates");
      this.items = this.$store.getters.getTextBlockTemplates;
      this.categories = this.getTextbausteinCategories;
      //console.log("blocks:"+JSON.stringify(this.items))
    }
  },
  computed: {
    ...mapGetters(["getTextbausteinCategories"]), //Erweitern überall wo direkt auf Store zugegriffen wird

    filteredItems() {
      let filtered = this.items;

      return filtered
        .sort((a, b) => {
          let fa = a.title.toLowerCase(),
            fb = b.title.toLowerCase();
          if (fa < fb) {
            return -1;
          }
          if (fa > fb) {
            return 1;
          }
          return 0;
        })
        .filter(item => {
          if (!this.search) return this.items;

          //TODO: Filterung auf Child-Elemente, die den Suchkriterien entsprechen
          //console.log(item.items.map(a => a.eltitle).filter(item => item.toLowerCase().includes(this.search.toLowerCase())));
          //console.log('check?');

          return (
            item.title.toLowerCase().includes(this.search.toLowerCase()) ||
            item.items
              .map(a => a.eltitle)
              .filter(item =>
                item.toLowerCase().includes(this.search.toLowerCase())
              ).length > 0
          );
        });
    }
  },
  async mounted() {
    await this.reloadTextBlockTemplates();
  },
  watch: {
    editedBlockModel(val) {
      if (val !== this.selectedText) {
        this.editedBlockModelUpdateButtonEnabled = false;
        this.editedBlockModelSelectButtonEnabled = false;
      }
    },
    editedBlockModelTitle(val) {
      if (val !== this.selectedTextTitle) {
        this.editedBlockModelUpdateButtonEnabled = false;
        this.editedBlockModelSelectButtonEnabled = false;
      }
    }
  },
  data() {
    return {
      descriptionTitle: "",
      rules: [v => v.length <= 50 || "Max 50 characters"],
      readUIVisibility: true,
      categoriesModel: null,
      textBlockModel: null,
      dialog: true,
      child: null,
      selectedItem: 1,
      selectedText: "",
      selectedTextTitle: "",
      selectedIndex: null,
      showBlockText: false,
      search: "",
      customwidth: "initial",
      categories: null, //TODO: Alle Kategorien aus Endpoint holen
      editedBlockModel: undefined,
      editedBlockModelTitle: undefined,
      editedBlockModelId: undefined,
      editedBlockModelCategory: undefined,
      editedBlockModelUpdateButtonEnabled: false,
      editedBlockModelSelectButtonEnabled: false,
      items: [],
      snackbar: false,
      snackbartext: null,
      snackColor: "primary",
      timeout: 2500
    };
  }
};
</script>

<style>
.bordered-dialog-list-group > div.v-list-group__header {
  border: 1px solid rgba(0, 0, 0, 0.42);
}

.v-dialog-textbausteine {
  overflow: hidden;
  overflow-y: hidden;
}

.edited-box-row .v-input__control {
  height: 100%;
}

.edited-box-row .v-input__control .v-input__slot {
  height: 100%;
}
</style>
