<template>
  <v-container class="pa-0 ma-0">
    <!--    <v-container v-if="Object.keys(getGutachtenDetails).length === 0" >-->
    <v-container v-if="Object.keys(this.auftrag).length === 0">
      <v-row align="center" justify="center">
        <h1>
          Bitte Gutachten aus der Liste auswählen
        </h1>
      </v-row>
    </v-container>
    <!--    <v-container-->
    <!--            class="pt-0 mt-0"-->
    <!--            v-if="Object.keys(getGutachtenDetails).length !== 0">-->
    <v-container
      v-if="Object.keys(this.auftrag).length !== 0"
      class="pt-0 mt-0"
    >
      <v-form ref="form" lazy-validation>
        <v-row>
          <!-- FZG STAMMDATEN - BLOCK  -->
          <v-col cols="4">
            <v-card>
              <v-toolbar color="teal" dark>
                <v-toolbar-title>
                  {{ auftrag.kopfdaten.fzg_stammdaten.title }}
                </v-toolbar-title>
              </v-toolbar>
              <v-list class="transparent pb-5">
                <v-list-item>
                  <v-row align="center" no-gutters>
                    <v-col sm="5">
                      {{
                        auftrag.kopfdaten.fzg_stammdaten.content.hersteller
                          .title
                      }}
                    </v-col>
                    <v-col sm="7">
                      <v-text-field
                        v-model="
                          auftrag.kopfdaten.fzg_stammdaten.content.hersteller
                            .value
                        "
                        hide-details
                      />
                    </v-col>
                  </v-row>
                </v-list-item>
                <v-list-item>
                  <v-row align="center" no-gutters>
                    <v-col sm="5">
                      {{
                        auftrag.kopfdaten.fzg_stammdaten.content.modell.title
                      }}
                    </v-col>
                    <v-col sm="7">
                      <v-text-field
                        v-model="
                          auftrag.kopfdaten.fzg_stammdaten.content.modell.value
                        "
                        hide-details
                      />
                    </v-col>
                  </v-row>
                </v-list-item>
                <v-list-item>
                  <v-row align="center" no-gutters>
                    <v-col sm="5">
                      {{ auftrag.kopfdaten.fzg_stammdaten.content.ez.title }}
                    </v-col>
                    <v-col sm="7">
                      <v-menu
                        v-model="menu_ez"
                        :close-on-content-click="false"
                        max-width="290"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="formattedErstzulassung"
                            v-bind="attrs"
                            v-on="on"
                            clearable
                            prepend-icon="mdi-calendar-range"
                            readonly
                            @click:clear="
                              auftrag.kopfdaten.fzg_stammdaten.content.ez.value =
                                ''
                            "
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="
                            auftrag.kopfdaten.fzg_stammdaten.content.ez.value
                          "
                          :max="currentDate"
                          first-day-of-week="1"
                          locale="de-de"
                          min="1950-01-01"
                          @change="menu_ez = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                </v-list-item>
                <v-list-item>
                  <v-row align="center" no-gutters>
                    <v-col sm="5">
                      {{
                        auftrag.kopfdaten.fzg_stammdaten.content.kennzeichen
                          .title
                      }}
                    </v-col>
                    <v-col sm="7">
                      <v-text-field
                        v-model="
                          auftrag.kopfdaten.fzg_stammdaten.content.kennzeichen
                            .value
                        "
                        hide-details
                      />
                    </v-col>
                  </v-row>
                </v-list-item>
                <v-list-item>
                  <v-row align="center" no-gutters>
                    <v-col sm="5">
                      {{
                        auftrag.kopfdaten.fzg_stammdaten.content.schadentag
                          .title
                      }}
                    </v-col>
                    <v-col sm="7">
                      <v-menu
                        v-model="menu_schadentag"
                        :close-on-content-click="false"
                        max-width="290"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-bind="attrs"
                            v-on="on"
                            :value="formattedSchaden"
                            clearable
                            min="erstzulassung"
                            prepend-icon="mdi-calendar-range"
                            readonly
                            @click:clear="
                              auftrag.kopfdaten.fzg_stammdaten.content.schadentag.value =
                                ''
                            "
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="
                            auftrag.kopfdaten.fzg_stammdaten.content.schadentag
                              .value
                          "
                          :max="currentDate"
                          :min="
                            auftrag.kopfdaten.fzg_stammdaten.content.ez.value
                          "
                          first-day-of-week="1"
                          locale="de-de"
                          @change="menu_schadentag = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                </v-list-item>
                <v-list-item>
                  <v-row align="center" no-gutters>
                    <v-col sm="5">
                      {{
                        auftrag.kopfdaten.fzg_stammdaten.content.fahrzeugalter
                          .title
                      }}
                    </v-col>
                    <v-col sm="7">
                      <v-text-field
                        hide-details
                        readonly
                        v-bind:value="calcDifference()"
                      />
                    </v-col>
                  </v-row>
                </v-list-item>
                <v-list-item>
                  <v-row align="center" no-gutters>
                    <v-col sm="5">
                      {{
                        auftrag.kopfdaten.fzg_stammdaten.content.fzg_ident_nr
                          .title
                      }}
                    </v-col>
                    <v-col sm="7">
                      <v-text-field
                        v-model="
                          auftrag.kopfdaten.fzg_stammdaten.content.fzg_ident_nr
                            .value
                        "
                        hide-details
                      />
                    </v-col>
                  </v-row>
                </v-list-item>
              </v-list>
            </v-card>
          </v-col>
          <!-- PRÜFBERICHT / VERS - BLOCK  -->
          <v-col cols="4">
            <v-card class="fill-height">
              <v-toolbar color="teal" dark>
                <v-toolbar-title>
                  {{ auftrag.kopfdaten.pruefbericht.title }}
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn
                  v-if="auftrag.kopfdaten.pruefbericht.download"
                  icon
                  v-on:click="
                    openDocument(auftrag.kopfdaten.pruefbericht.download)
                  "
                >
                  <v-icon>mdi-download</v-icon>
                </v-btn>
              </v-toolbar>
              <v-list class="transparent pb-5">
                <v-list-item>
                  <v-row align="center" no-gutters>
                    <v-col sm="5">
                      {{
                        auftrag.kopfdaten.pruefbericht.content.versicherer.title
                      }}
                    </v-col>
                    <v-col sm="7">
                      <v-text-field
                        v-model="
                          auftrag.kopfdaten.pruefbericht.content.versicherer
                            .value
                        "
                        hide-details
                      />
                    </v-col>
                  </v-row>
                </v-list-item>
                <v-list-item>
                  <v-row align="center" no-gutters>
                    <v-col sm="5">
                      {{ auftrag.kopfdaten.pruefbericht.content.vsnr.title }}
                    </v-col>
                    <v-col sm="7">
                      <v-text-field
                        v-model="
                          auftrag.kopfdaten.pruefbericht.content.vsnr.value
                        "
                        hide-details
                      />
                    </v-col>
                  </v-row>
                </v-list-item>
                <v-list-item>
                  <v-row align="center" no-gutters>
                    <v-col sm="5">
                      {{
                        auftrag.kopfdaten.pruefbericht.content.schadennummer
                          .title
                      }}
                    </v-col>
                    <v-col sm="7">
                      <v-text-field
                        v-model="
                          auftrag.kopfdaten.pruefbericht.content.schadennummer
                            .value
                        "
                        hide-details
                      />
                    </v-col>
                  </v-row>
                </v-list-item>
                <v-list-item>
                  <v-row align="center" no-gutters>
                    <v-col sm="5">
                      {{
                        auftrag.kopfdaten.pruefbericht.content.ersteller.title
                      }}
                    </v-col>
                    <v-col sm="7">
                      <v-text-field
                        v-model="
                          auftrag.kopfdaten.pruefbericht.content.ersteller.value
                        "
                        hide-details
                      />
                    </v-col>
                  </v-row>
                </v-list-item>
                <v-list-item>
                  <v-row align="center" no-gutters>
                    <v-col sm="5">
                      {{
                        auftrag.kopfdaten.pruefbericht.content.bearbeiter.title
                      }}
                    </v-col>
                    <v-col sm="7">
                      <v-text-field
                        v-model="
                          auftrag.kopfdaten.pruefbericht.content.bearbeiter
                            .value
                        "
                        hide-details
                      />
                    </v-col>
                  </v-row>
                </v-list-item>
                <v-list-item>
                  <v-row align="center" no-gutters>
                    <v-col sm="5">
                      {{ auftrag.kopfdaten.pruefbericht.content.datum.title }}
                    </v-col>
                    <v-col sm="7">
                      <v-text-field
                        v-model="
                          auftrag.kopfdaten.pruefbericht.content.datum.value
                        "
                        hide-details
                      />
                    </v-col>
                  </v-row>
                </v-list-item>
                <v-list-item>
                  <v-row align="center" no-gutters>
                    <v-col sm="5">
                      {{ auftrag.kopfdaten.pruefbericht.content.p_nr.title }}
                    </v-col>
                    <v-col sm="7">
                      <v-text-field
                        v-model="
                          auftrag.kopfdaten.pruefbericht.content.p_nr.value
                        "
                        hide-details
                      />
                    </v-col>
                  </v-row>
                </v-list-item>
              </v-list>
            </v-card>
          </v-col>
          <!-- VERWENDETE REFERENZ FIRMA - BLOCK  -->
          <v-col cols="4">
            <v-card class="fill-height">
              <v-toolbar color="teal" dark>
                <v-toolbar-title
                  >{{ auftrag.kopfdaten.referenzwerkstatt.title }}
                </v-toolbar-title>
              </v-toolbar>
              <v-tabs v-model="tab" color="teal">
                <v-tabs-slider></v-tabs-slider>
                <v-tab
                  v-for="index in auftrag.kopfdaten.referenzwerkstatt.content
                    .length + 1"
                  :key="index"
                >
                  <v-row
                    v-if="
                      index <
                        auftrag.kopfdaten.referenzwerkstatt.content.length + 1
                    "
                    align="center"
                    style="min-width: 90px"
                  >
                    <v-col cols="6">{{ index }}</v-col>
                    <v-col cols="4">
                      <v-icon
                        color="red darken-2"
                        medium
                        @click="showRemovalDialog('Werkstatt', index - 1)"
                      >
                        mdi-delete-forever
                      </v-icon>
                    </v-col>
                  </v-row>
                  <v-row v-else align="center" style="min-width: 90px">
                    <v-col cols="12">
                      <v-icon
                        color="blue darken-1"
                        large
                        @click="addEmptyWerkstatt"
                      >
                        mdi-plus
                      </v-icon>
                    </v-col>
                  </v-row>
                </v-tab>
                <v-tabs-items v-model="tab">
                  <v-tab-item
                    v-for="item in auftrag.kopfdaten.referenzwerkstatt.content"
                    :key="item.title"
                  >
                    <v-list class="transparent pb-5">
                      <v-list-item>
                        <v-row align="center" no-gutters>
                          <v-col sm="5">
                            Name:
                          </v-col>
                          <v-col sm="7">
                            <v-text-field
                              v-model="item.metadata.name"
                              hide-details
                            />
                          </v-col>
                        </v-row>
                      </v-list-item>
                      <v-list-item>
                        <v-row align="center" no-gutters>
                          <v-col sm="5">
                            PLZ / Ort:
                          </v-col>
                          <v-col sm="2">
                            <v-text-field
                              v-model="item.metadata.plz"
                              hide-details
                            />
                          </v-col>
                          <v-col sm="5">
                            <v-text-field
                              v-model="item.metadata.ort"
                              hide-details
                            />
                          </v-col>
                        </v-row>
                      </v-list-item>
                      <v-list-item>
                        <v-row align="center" no-gutters>
                          <v-col sm="5">
                            Straße:
                          </v-col>
                          <v-col sm="7">
                            <v-text-field
                              v-model="item.metadata.strasse"
                              hide-details
                            />
                          </v-col>
                        </v-row>
                      </v-list-item>
                      <v-list-item>
                        <v-row align="center" no-gutters>
                          <v-col sm="5">
                            Telefon:
                          </v-col>
                          <v-col sm="7">
                            <v-text-field
                              v-model="item.metadata.telefon"
                              hide-details
                            />
                          </v-col>
                        </v-row>
                      </v-list-item>
                      <v-list-item>
                        <v-row align="center" no-gutters>
                          <v-col sm="5">
                            Distanz ref / echt:
                          </v-col>
                          <v-col sm="4">
                            <v-text-field
                              v-model="item.metadata.entfernung"
                              hide-details
                            />
                          </v-col>
                          <v-col sm="3">
                            <!--                        <v-text-field value="22 km"></v-text-field>-->
                            <v-text-field
                              v-model="item.metadata.reale_entfernung"
                              hide-details
                            />
                          </v-col>
                        </v-row>
                      </v-list-item>
                    </v-list>
                  </v-tab-item>
                </v-tabs-items>
              </v-tabs>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <!-- GUTACHTEN - BLOCK  -->
          <v-col cols="4">
            <v-card class="fill-height">
              <v-toolbar color="teal" dark>
                <v-toolbar-title
                  >{{ auftrag.kopfdaten.gutachten.title }}
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn
                  v-if="auftrag.kopfdaten.gutachten.download"
                  icon
                  v-on:click="
                    openDocument(auftrag.kopfdaten.gutachten.download)
                  "
                >
                  <v-icon>mdi-download</v-icon>
                </v-btn>
              </v-toolbar>
              <v-list class="transparent pb-5">
                <v-list-item>
                  <v-row align="center" no-gutters>
                    <v-col sm="5">
                      {{
                        auftrag.kopfdaten.gutachten.content.sachverstaendiger
                          .title
                      }}
                    </v-col>
                    <v-col sm="7">
                      <v-text-field
                        v-model="
                          auftrag.kopfdaten.gutachten.content.sachverstaendiger
                            .value
                        "
                        hide-details
                      />
                    </v-col>
                  </v-row>
                </v-list-item>
                <v-list-item>
                  <v-row align="center" no-gutters>
                    <v-col sm="5">
                      {{
                        auftrag.kopfdaten.gutachten.content.gutachtendatum.title
                      }}
                    </v-col>
                    <v-col sm="7">
                      <v-text-field
                        v-model="
                          auftrag.kopfdaten.gutachten.content.gutachtendatum
                            .value
                        "
                        hide-details
                      />
                    </v-col>
                  </v-row>
                </v-list-item>
                <v-list-item>
                  <v-row align="center" no-gutters>
                    <v-col sm="5">
                      {{
                        auftrag.kopfdaten.gutachten.content.gutachtennummer
                          .title
                      }}
                    </v-col>
                    <v-col sm="7">
                      <v-text-field
                        v-model="
                          auftrag.kopfdaten.gutachten.content.gutachtennummer
                            .value
                        "
                        hide-details
                      />
                    </v-col>
                  </v-row>
                </v-list-item>
              </v-list>
            </v-card>
          </v-col>
          <!-- KUNDE - BLOCK  -->
          <v-col cols="4">
            <v-card>
              <v-toolbar color="teal" dark>
                <v-toolbar-title>
                  {{ auftrag.kopfdaten.kunde.title }}
                </v-toolbar-title>
              </v-toolbar>
              <v-list class="transparent pb-5">
                <v-list-item>
                  <v-row align="center" no-gutters>
                    <v-col sm="4">
                      {{ auftrag.kopfdaten.kunde.content.vorname.title }}
                    </v-col>
                    <v-col sm="8">
                      <v-text-field
                        v-model="auftrag.kopfdaten.kunde.content.vorname.value"
                        hide-details
                      />
                    </v-col>
                  </v-row>
                </v-list-item>
                <v-list-item>
                  <v-row align="center" no-gutters>
                    <v-col sm="4">
                      {{ auftrag.kopfdaten.kunde.content.nachname.title }}
                    </v-col>
                    <v-col sm="8">
                      <v-text-field
                        v-model="auftrag.kopfdaten.kunde.content.nachname.value"
                        hide-details
                      />
                    </v-col>
                  </v-row>
                </v-list-item>
                <v-list-item>
                  <v-row align="center" no-gutters>
                    <v-col sm="4">
                      {{ auftrag.kopfdaten.kunde.content.strasse.title }}
                    </v-col>
                    <v-col sm="8">
                      <v-text-field
                        v-model="auftrag.kopfdaten.kunde.content.strasse.value"
                        hide-details
                      />
                    </v-col>
                  </v-row>
                </v-list-item>
                <v-list-item>
                  <v-row align="center" no-gutters>
                    <v-col sm="4">
                      {{ auftrag.kopfdaten.kunde.content.plz_ort.title }}
                    </v-col>
                    <v-col sm="8">
                      <v-text-field
                        v-model="auftrag.kopfdaten.kunde.content.plz_ort.value"
                        class="text-wrap"
                        hide-details
                      />
                    </v-col>
                  </v-row>
                </v-list-item>
              </v-list>
            </v-card>
          </v-col>
          <!-- RECHTSANWALT - BLOCK  -->
          <v-col cols="4">
            <v-card>
              <v-toolbar color="teal" dark>
                <v-toolbar-title>{{
                  auftrag.kopfdaten.ra.title
                }}</v-toolbar-title>
              </v-toolbar>
              <v-list class="transparent pb-5">
                <v-list-item>
                  <v-row align="center" no-gutters>
                    <v-col sm="4">
                      {{ auftrag.kopfdaten.ra.content.name.title }}
                    </v-col>
                    <v-col sm="8">
                      <v-text-field
                        v-model="auftrag.kopfdaten.ra.content.name.value"
                        class="text-wrap"
                        hide-details
                      />
                    </v-col>
                  </v-row>
                </v-list-item>
                <v-list-item>
                  <v-row align="center" no-gutters>
                    <v-col sm="4">
                      {{ auftrag.kopfdaten.ra.content.strasse.title }}
                    </v-col>
                    <v-col sm="8">
                      <v-text-field
                        v-model="auftrag.kopfdaten.ra.content.strasse.value"
                        class="text-wrap"
                        hide-details
                      />
                    </v-col>
                  </v-row>
                </v-list-item>
                <v-list-item>
                  <v-row align="center" no-gutters>
                    <v-col sm="4">
                      {{ auftrag.kopfdaten.ra.content.plz_ort.title }}
                    </v-col>
                    <v-col sm="8">
                      <v-text-field
                        v-model="auftrag.kopfdaten.ra.content.plz_ort.value"
                        class="text-wrap"
                        hide-details
                      />
                    </v-col>
                  </v-row>
                </v-list-item>
                <v-list-item>
                  <v-row align="center" no-gutters>
                    <v-col sm="4">
                      {{ auftrag.kopfdaten.ra.content.aktenzeichen.title }}
                    </v-col>
                    <v-col sm="8">
                      <v-text-field
                        v-model="
                          auftrag.kopfdaten.ra.content.aktenzeichen.value
                        "
                        class="text-wrap"
                        hide-details
                      />
                    </v-col>
                  </v-row>
                </v-list-item>
              </v-list>
            </v-card>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <!-- Zusammenfassung -->
        <v-row>
          <v-col cols="12">
            <v-card>
              <v-toolbar color="teal" dark>
                <v-toolbar-title>
                  Zusammenfassung
                </v-toolbar-title>
              </v-toolbar>
              <!--<v-container class="pa-md-4 mx-lg-auto pb-md-0">
                <v-row>
                  <v-col cols="1"></v-col>
                  <v-col> {{ auftrag.minderwerte.header[0] }}</v-col>
                  <v-col> {{ auftrag.minderwerte.header[1] }}</v-col>
                  <v-col> {{ auftrag.minderwerte.header[4] }}</v-col>
                </v-row>
              </v-container>-->
              <v-container
                v-for="(item, index) in auftrag.minderwerte.rows"
                :key="index"
                class="pt-8 pb-8 pl-6 pr-6"
              >
                <v-row>
                  <v-col cols="auto">
                    <v-row class="fill-height">
                      <v-col class="fill-height">
                        <v-row class="fill-height">
                          <v-col style="display:flex; align-items: center">
                            <v-icon
                              color="red lighten-1"
                              large
                              style="height:fit-content"
                              @click="
                                removeMinderwerteFormularLine(
                                  auftrag.minderwerte.rows,
                                  index
                                )
                              "
                              >mdi-timeline-remove
                            </v-icon>
                          </v-col>
                          <v-col class="pt-4 pb-2 pr-2">
                            <div class="brace-border-div"></div>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col>
                    <v-row>
                      <v-col>
                        <v-text-field
                          v-model="item[0]"
                          :label="auftrag.minderwerte.header[0]"
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col>
                        <v-text-field
                          v-model="item[1]"
                          :label="auftrag.minderwerte.header[1]"
                          outlined
                          prefix="€"
                        ></v-text-field>
                      </v-col>
                      <v-col>
                        <v-text-field
                          v-model="item[4]"
                          :label="auftrag.minderwerte.header[4]"
                          outlined
                          prefix="€"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row v-for="(block, index) in item[6]" :key="index">
                      <v-col>
                        <v-textarea
                          v-model="item[6][index]"
                          :append-icon="
                            index > 0 ? 'mdi-table-row-remove' : undefined
                          "
                          :label="`Texte zu ` + item[0]"
                          append-outer-icon="mdi-table-row-plus-after"
                          auto-grow
                          hide-details
                          outlined
                          prepend-icon="mdi-message-plus"
                          rows="2"
                          @click:prepend="showDialog(item, index, 'multi')"
                          @click:append="removeRow(item[6], index)"
                          @click:append-outer="addRow(item[6])"
                        ></v-textarea>
                      </v-col>
                    </v-row>
                    <v-row></v-row>
                  </v-col>
                </v-row>
              </v-container>
              <v-container>
                <v-btn block color="info" @click="addNewMinderwerteLine">
                  <v-icon light>
                    mdi-plus
                  </v-icon>
                  Neue Zeile einfügen
                </v-btn>
              </v-container>
              <v-divider></v-divider>
              <v-container>
                <v-row
                  v-if="
                    auftrag.minderwerte.summary !== undefined &&
                      auftrag.minderwerte.summary.length === 6
                  "
                >
                  <v-col cols="auto">
                    <v-row class="fill-height">
                      <v-col class="fill-height">
                        <v-row class="fill-height">
                          <v-col style="display:flex; align-items: center">
                            <v-icon
                              color="red lighten-1"
                              large
                              style="height:fit-content"
                              @click="removeMinderwerteSummaryFormularLine"
                              >mdi-delete-forever
                            </v-icon>
                          </v-col>
                          <v-col class="pt-4 pb-2 pr-2">
                            <div class="brace-border-div"></div>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col>
                    <v-row>
                      <v-col>
                        <v-text-field
                          v-model="auftrag.minderwerte.summary[0]"
                          :label="auftrag.minderwerte.header[0]"
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col>
                        <v-text-field
                          v-model="auftrag.minderwerte.summary[1]"
                          :class="
                            firstSummaryFieldRuleValue === true
                              ? 'non-hint-color'
                              : 'hint-color'
                          "
                          :hint="
                            firstSummaryFieldRuleValue === true
                              ? ''
                              : 'Berechneter Wert ist mathematisch nicht korrekt'
                          "
                          :label="auftrag.minderwerte.header[1]"
                          outlined
                          persistent-hint
                          prefix="€"
                        ></v-text-field>
                      </v-col>
                      <v-col>
                        <v-text-field
                          v-model="auftrag.minderwerte.summary[4]"
                          :class="
                            fourthSummaryFieldRuleValue === true
                              ? 'non-hint-color'
                              : 'hint-color'
                          "
                          :hint="
                            fourthSummaryFieldRuleValue === true
                              ? ''
                              : 'Berechneter Wert ist mathematisch nicht korrekt'
                          "
                          :label="auftrag.minderwerte.header[4]"
                          outlined
                          persistent-hint
                          prefix="€"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-btn v-else block color="info" @click="addNewSummaryLine">
                  <v-icon light>
                    mdi-plus
                  </v-icon>
                  Summenzeile einfügen
                </v-btn>
              </v-container>
            </v-card>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <!-- Sachverständige Stellungnahme zum Prüfbericht -->
        <v-row>
          <v-col cols="12">
            <v-card>
              <v-toolbar color="teal" dark>
                <v-toolbar-title>
                  Sachverständige Stellungnahme zum Prüfbericht
                </v-toolbar-title>
              </v-toolbar>
              <v-container
                v-for="(item, index) in auftrag.technische_pruefung.rows"
                :key="index"
                class="pt-8 pb-8 pl-6 pr-6"
              >
                <v-row>
                  <v-col cols="auto">
                    <v-row class="fill-height">
                      <v-col class="fill-height">
                        <v-row class="fill-height">
                          <v-col style="display:flex; align-items: center">
                            <v-icon
                              color="red lighten-1"
                              large
                              style="height:fit-content"
                              @click="
                                removeStellungnahmeFormularLine(
                                  auftrag.technische_pruefung.rows,
                                  index
                                )
                              "
                              >mdi-timeline-remove
                            </v-icon>
                          </v-col>
                          <v-col class="pt-4 pb-2 pr-2">
                            <div class="brace-border-div"></div>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col>
                    <v-row class="pb-4">
                      <v-col class="pb-0">
                        <v-text-field
                          v-model="item[0]"
                          :label="auftrag.technische_pruefung.header[0]"
                          hide-details
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col class="pb-0">
                        <v-text-field
                          v-model="item[1]"
                          :label="auftrag.technische_pruefung.header[1]"
                          hide-details
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col class="pb-0">
                        <v-text-field
                          v-model="item[2]"
                          :label="auftrag.technische_pruefung.header[4]"
                          hide-details
                          outlined
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row v-for="(block, index) in item[3]" :key="index">
                      <v-col>
                        <v-textarea
                          v-model="item[3][index]"
                          :append-icon="
                            hideIcon(index) ? 'mdi-table-row-remove' : undefined
                          "
                          :label="`Texte zu ` + item[0]"
                          append-outer-icon="mdi-table-row-plus-after"
                          auto-grow
                          hide-details
                          outlined
                          prepend-icon="mdi-message-plus"
                          rows="2"
                          @click:prepend="showDialog(item, index, 'multi')"
                          @click:append="removeRow(item[3], index)"
                          @click:append-outer="addRow(item[3])"
                        ></v-textarea>
                      </v-col>
                    </v-row>
                    <v-row></v-row>
                  </v-col>
                </v-row>
              </v-container>
              <v-container>
                <v-btn block color="info" @click="addNewStellungnahmeLine">
                  <v-icon light>
                    mdi-plus
                  </v-icon>
                  Neue Zeile einfügen
                </v-btn>
              </v-container>
            </v-card>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <!-- Verrechnungssätze Referenfwerkstatt -->
        <v-row>
          <v-col cols="12">
            <v-card>
              <v-toolbar color="teal" dark>
                <v-toolbar-title>
                  {{ auftrag.verrechnung_referenzwerkstatt.title }}
                </v-toolbar-title>
              </v-toolbar>
              <v-container class="pt-8 pb-8 pl-6 pr-6">
                <v-row
                  v-for="(item, index) in auftrag.verrechnung_referenzwerkstatt
                    .rows"
                  :key="index"
                >
                  <v-col cols="auto">
                    <v-row class="fill-height">
                      <v-col class="fill-height">
                        <v-row class="fill-height">
                          <v-col style="display:flex; align-items: center">
                            <v-icon
                              color="red lighten-1"
                              large
                              style="height:fit-content"
                              @click="
                                removeRefWerkstattFormularLine(
                                  auftrag.verrechnung_referenzwerkstatt.rows,
                                  index
                                )
                              "
                              >mdi-delete-forever
                            </v-icon>
                          </v-col>
                          <v-col class="pt-4 pb-2 pr-2">
                            <div class="brace-border-div"></div>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col>
                    <v-row>
                      <v-col>
                        <v-text-field
                          v-model="item[0]"
                          :label="
                            auftrag.verrechnung_referenzwerkstatt.header[0]
                          "
                          hide-details
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col>
                        <v-text-field
                          v-model="item[1]"
                          :label="
                            auftrag.verrechnung_referenzwerkstatt.header[1]
                          "
                          hide-details
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col>
                        <v-text-field
                          v-model="item[2]"
                          :label="
                            auftrag.verrechnung_referenzwerkstatt.header[2]
                          "
                          hide-details
                          outlined
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row></v-row>
                  </v-col>
                </v-row>
              </v-container>
              <v-container class="pt-0 mt-0">
                <v-btn block color="info" @click="addNewRefWerkstattLine">
                  <v-icon light>
                    mdi-plus
                  </v-icon>
                  Neue Zeile einfügen
                </v-btn>
              </v-container>
              <v-divider></v-divider>
              <v-container class="pa-md-4 mx-lg-auto">
                <v-row
                  v-for="(block, index) in auftrag.verrechnung_referenzwerkstatt
                    .reasons"
                  :key="index"
                >
                  <v-col>
                    <v-textarea
                      v-model="
                        auftrag.verrechnung_referenzwerkstatt.reasons[index]
                      "
                      :append-icon="
                        hideIcon(index) ? 'mdi-table-row-remove' : undefined
                      "
                      append-outer-icon="mdi-table-row-plus-after"
                      auto-grow
                      label="Texte zu Verrechnungssätzen"
                      outlined
                      prepend-icon="mdi-message-plus"
                      rows="2"
                      @click:prepend="
                        showDialog(
                          auftrag.verrechnung_referenzwerkstatt.reasons,
                          index,
                          'simple'
                        )
                      "
                      @click:append="
                        removeRow(
                          auftrag.verrechnung_referenzwerkstatt.reasons,
                          index
                        )
                      "
                      @click:append-outer="
                        addRow(auftrag.verrechnung_referenzwerkstatt.reasons)
                      "
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row>
          <v-col cols="12">
            <v-card>
              <v-toolbar color="teal" dark>
                <v-toolbar-title>
                  {{ auftrag.sachverstaendige_feststellungen.title }}
                </v-toolbar-title>
              </v-toolbar>
              <v-container class="pt-8 pb-8 pl-6 pr-6">
                <v-row
                  v-for="(block, index) in auftrag
                    .sachverstaendige_feststellungen.reasons"
                  :key="index"
                >
                  <v-col>
                    <v-textarea
                      v-model="
                        auftrag.sachverstaendige_feststellungen.reasons[index]
                      "
                      :append-icon="
                        hideIcon(index) ? 'mdi-table-row-remove' : undefined
                      "
                      append-outer-icon="mdi-table-row-plus-after"
                      auto-grow
                      label="Texte zur Sachverständigen Stellungnahme"
                      outlined
                      prepend-icon="mdi-message-plus"
                      rows="2"
                      @click:prepend="
                        showDialog(
                          auftrag.sachverstaendige_feststellungen.reasons,
                          index,
                          'simple'
                        )
                      "
                      @click:append="
                        removeRow(
                          auftrag.sachverstaendige_feststellungen.reasons,
                          index
                        )
                      "
                      @click:append-outer="
                        addRow(auftrag.sachverstaendige_feststellungen.reasons)
                      "
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row v-if="currentStageName !== 'done'">
          <v-col class="text-right">
            <v-btn
              class="ma-2 mr-20"
              color="warning"
              large
              style="margin-right: 100px !important"
              @click.native.stop="showResetDialog"
              >Zurücksetzen
            </v-btn>
            <v-btn
              class="ma-2"
              color="success"
              large
              @click.native.stop="formPersistState"
              >Speichern
            </v-btn>
            <v-btn
              :disabled="checkSumsIncorrect.status"
              class="ma-2"
              color="primary"
              large
              @click.native.stop="formCommit"
              >{{
                this.currentStageName === "review" ? "Weiter" : "Zu Freigabe"
              }}
            </v-btn>
          </v-col>
        </v-row>

        <Textbausteine
          v-if="dialog"
          v-on:close-dialog="closeDialog"
        ></Textbausteine>

        <DialogResetInputFormular
          v-if="resetdialogIsOpen"
          :dialogOpened="true"
          @update-reset-dialog-state="updateResetDialogState"
        ></DialogResetInputFormular>
      </v-form>
    </v-container>
    <v-row justify="center">
      <v-dialog v-model="confirmDeleteDialog" max-width="290" persistent>
        <v-card>
          <v-toolbar color="teal" dark>
            <v-toolbar-title>
              {{ this.deleteDialogName }} wirklich löschen?
            </v-toolbar-title>
          </v-toolbar>
          <v-card-text
            >Achtung: Dieser Vorgang kann nicht rückgängig gemacht
            werden.</v-card-text
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red darken-1" text @click="confirmRemoval">
              Ja
            </v-btn>
            <v-btn color="primary darken-1" text @click="hideRemovalDialog">
              Nein
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-snackbar v-model="snackbar" :color="snackColor" :timeout="timeout">
        {{ snackbartext }}

        <template v-slot:action="{ attrs }">
          <v-btn v-bind="attrs" color="white" text @click="snackbar = false">
            X
          </v-btn>
        </template>
      </v-snackbar>
    </v-row>
  </v-container>
</template>

<script>
import downloadLinkSigner from "../services/downloadLinkSigner";
import Textbausteine from "@/components/Textbausteine";
import DialogResetInputFormular from "@/components/DialogResetInputFormular";

import moment from "moment";
import "moment/locale/de"; // without this line it didn't work
moment.locale("de");

import { mapGetters } from "vuex";
import _ from "lodash";

moment.locale("de");

const emptyWerkstattObject = {
  metadata: {
    name: "Verwendete Referenz-Firma",
    strasse: "undefined",
    plz: "undefined",
    ort: "undefined",
    telefon: "undefined",
    entfernung: "0 km",
    reale_entfernung: "0 km"
  },
  additional_information: {
    "1": "Verbringungskosten: 0,00 €",
    "2": "UPE-Aufschlag (0%): 0,00 €",
    "3": "undefined"
  },
  prices: {
    "1": {
      name: "Lohnfaktoren / Std.",
      headers: ["Leistung", "Preis"],
      rows: [
        ["Mechanik", "0,00 €"],
        ["Elektrik", "0,00 €"],
        ["Karosserie", "0,00 €"],
        ["Lack inkl. Lackmaterial", "0,00 €"]
      ]
    }
  }
};

export default {
  props: ["currentStage"],
  name: "AuftragDetail",
  components: { Textbausteine, DialogResetInputFormular },

  created() {
    this.timer = setInterval(this.checkMinderwerteSumScheduler, 3000);
    this.timer2 = setInterval(this.checkAndResetStepper, 3000);
  },
  mounted() {},
  computed: {
    ...mapGetters(["getGutachtenDetails", "getUserPermissions"]),
    formattedSchaden() {
      if (
        this.auftrag.kopfdaten.fzg_stammdaten.content.schadentag.value ===
          "undefined" ||
        this.auftrag.kopfdaten.fzg_stammdaten.content.schadentag.value === ""
      ) {
        return "";
      } else {
        let m = moment(
          this.auftrag.kopfdaten.fzg_stammdaten.content.schadentag.value
        );
        if (m.isValid()) {
          return m.format("DD.MM.YYYY");
        } else {
          return "";
        }
      }
    },
    formattedErstzulassung() {
      if (
        this.auftrag.kopfdaten.fzg_stammdaten.content.ez.value ===
          "undefined" ||
        this.auftrag.kopfdaten.fzg_stammdaten.content.ez.value === ""
      ) {
        return "";
      } else {
        let m = moment(this.auftrag.kopfdaten.fzg_stammdaten.content.ez.value);
        if (m.isValid()) {
          return m.format("DD.MM.YYYY");
        } else {
          return "";
        }
      }
    },
    firstSummaryFieldRule: function() {
      const index = 1;
      const erText = "Berechneter Wert ist mathematisch nicht korrekt";
      if (this === undefined || this.auftrag === undefined) return "net def";

      if (
        this.auftrag.minderwerte === undefined ||
        this.auftrag.minderwerte.rows === undefined ||
        this.auftrag.minderwerte.rows.length === 0
      ) {
        this.setFirstSummaryFieldRuleValue(false);
        return erText;
      }

      const value = this.auftrag.minderwerte.summary[index];
      //alert('orig_val'+value)
      if (value === undefined || value === "") {
        this.setFirstSummaryFieldRuleValue(false);
        return erText;
      }
      let sum = Number("0.00");
      for (let row of this.auftrag.minderwerte.rows) {
        sum = sum + Number(row[index].replace(/\./g, "").replace(/,/, "."));
      }
      //round sum
      sum = Number(Math.round(sum + "e+2") + "e-2");
      if (Number(value.replace(/\./g, "").replace(/,/, ".")) === sum) {
        this.setNewCheckSumStatus(index, false);
        this.setFirstSummaryFieldRuleValue(true);
        return true;
      } else {
        this.setNewCheckSumStatus(index, true);
        this.setFirstSummaryFieldRuleValue(false);
        return erText;
      }
    },
    fourthSummaryFieldRule: function() {
      const index = 4;
      const erText = "Berechneter Wert ist mathematisch nicht korrekt";
      if (this === undefined || this.auftrag === undefined) return "net def";

      if (
        this.auftrag.minderwerte === undefined ||
        this.auftrag.minderwerte.rows === undefined ||
        this.auftrag.minderwerte.rows.length === 0
      ) {
        this.setFourthSummaryFieldRuleValue(false);
        return erText;
      }

      const value = this.auftrag.minderwerte.summary[index];
      //console.log('Sum 4 Column:'+value)
      if (value === undefined || value === "") {
        this.setFourthSummaryFieldRuleValue(false);
        return erText;
      }
      let sum = Number("0.00");
      for (let row of this.auftrag.minderwerte.rows) {
        //console.log('Row 4 Column:'+Number(row[index].replace(/\./g, "").replace(/,/, ".")))
        sum = sum + Number(row[index].replace(/\./g, "").replace(/,/, "."));
      }
      //round sum
      sum = Number(Math.round(sum + "e+2") + "e-2");

      //console.log('Column 4 vergleich:'+Number(value.replace(/\./g, "").replace(/,/, "."))+" === "+sum)
      if (Number(value.replace(/\./g, "").replace(/,/, ".")) === sum) {
        this.setNewCheckSumStatus(index, false);
        this.setFourthSummaryFieldRuleValue(true);
        return true;
      } else {
        this.setNewCheckSumStatus(index, true);
        this.setFourthSummaryFieldRuleValue(false);
        //console.log('column4 return error');
        return erText;
      }
    }
  },
  watch: {
    getGutachtenDetails(newValue, oldValue) {
      if (oldValue !== undefined) console.log("old value exists");
      //console.log("GDETAILS before LOAD:"+JSON.stringify(newValue))
      this.auftrag = JSON.parse(JSON.stringify(newValue));
      this.$emit("update-auftrag-state", true);
      //console.log("GUTACHTEN-DETAILS:"+JSON.stringify(this.auftrag.verrechnung_referenzwerkstatt))
    }
  },
  methods: {
    calcDifference() {
      let ez = moment(this.auftrag.kopfdaten.fzg_stammdaten.content.ez.value);
      let schaden = moment(
        this.auftrag.kopfdaten.fzg_stammdaten.content.schadentag.value
      );

      //Sicherstellen, dass die Datumsangaben ein korrektes Format haben
      if (ez.isValid() && schaden.isValid()) {
        let years = schaden.diff(ez, "year");
        ez.add(years, "years");

        let months = schaden.diff(ez, "months");
        ez.add(months, "months");

        let days = schaden.diff(ez, "days");
        let fahrzeugalter =
          years + " Jahr(e), " + months + " Mon., " + days + " Tag(e)";

        this.auftrag.kopfdaten.fzg_stammdaten.content.fahrzeugalter.value = fahrzeugalter;
        return fahrzeugalter;
      } else {
        this.auftrag.kopfdaten.fzg_stammdaten.content.fahrzeugalter.value =
          "Erstzulassung/Schadendatum auswählen";
        return "Erstzulassung/Schadendatum auswählen";
      }
    },

    customTimeout(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    async formPersistState() {
      this.$store.commit("setGutachtenDetailOverlay", true);
      await this.customTimeout(200);

      const origO = this.$store.getters.getGutachtenDetails;
      let isEqual = _.isEqual(this.auftrag, origO); // true
      //console.log('isEqual:'+JSON.stringify(isEqual))
      if (!isEqual) {
        await this.$store.dispatch("persistDataIntoCurrentStage", {
          id: this.auftrag.metadata.id,
          data: this.auftrag
        });
      }
      this.$store.commit("setGutachtenDetailOverlay", false);
    },
    async formCommit() {
      if (this.currentStageName === "review") {
        await this.formPersistState();
        this.$emit("jump-to-next-step");
      } else await this.pushToNextStage();
    },
    checkMinderwerteSumScheduler() {
      this.firstSummaryFieldRule;
      this.fourthSummaryFieldRule;
    },
    checkAndResetStepper() {
      if (this.currentStageName === "review") {
        const origO = this.$store.getters.getGutachtenDetails;
        let isEqual = _.isEqual(this.auftrag, origO); // true
        if (!isEqual) this.$emit("reset-stepper-steps");
      }
    },
    setFourthSummaryFieldRuleValue(newValue) {
      this.fourthSummaryFieldRuleValue = newValue;
    },
    setFirstSummaryFieldRuleValue(newValue) {
      this.firstSummaryFieldRuleValue = newValue;
    },
    setNewCheckSumStatus(idx, status) {
      //console.log("IDx:"+idx)
      this.checkSumsIncorrect.columns[idx.toString()] = status;
      let st = false;
      for (let value of Object.values(this.checkSumsIncorrect.columns)) {
        if (value.toString() === "true") {
          st = true;
          break;
        }
      }
      this.checkSumsIncorrect.status = st;
      //console.log("item:"+JSON.stringify(this.checkSumsIncorrect));
    },
    addRow(item) {
      item.push("");
      console.log(item);
    },
    addNewSummaryLine() {
      this.auftrag.minderwerte.summary = [
        "Gesamtbetrag",
        "0,00",
        "0,00",
        "0,00",
        "0,00",
        "0,00"
      ];
    },
    addNewMinderwerteLine() {
      this.auftrag.minderwerte.rows.push([
        "undefined",
        "0,00",
        "0,00",
        "0,00",
        "0,00",
        "0,00",
        [""]
      ]);
    },
    addNewStellungnahmeLine() {
      this.auftrag.technische_pruefung.rows.push(["", "", "", [""]]);
    },
    addNewRefWerkstattLine() {
      this.auftrag.verrechnung_referenzwerkstatt.rows.push(["", "", ""]);
    },
    removeMinderwerteSummaryFormularLine() {
      this.auftrag.minderwerte.summary = [];
    },
    removeMinderwerteFormularLine(items, index) {
      items.splice(index, 1);
    },
    removeStellungnahmeFormularLine(items, index) {
      items.splice(index, 1);
    },
    removeRefWerkstattFormularLine(items, index) {
      items.splice(index, 1);
    },
    removeWerkstattTab(index) {
      //todo can't switch selected tab to "1"
      this.tab = "1";
      this.auftrag.kopfdaten.referenzwerkstatt.content.splice(index, 1);
    },
    addEmptyWerkstatt() {
      let tmpObj = JSON.parse(JSON.stringify(emptyWerkstattObject));
      this.auftrag.kopfdaten.referenzwerkstatt.content.push(tmpObj);
    },
    async updateResetDialogState(reset) {
      this.resetdialogIsOpen = false;
      if (reset) {
        this.$store.dispatch("fetchGutachtenDetails", this.auftrag.metadata.id);
        window.scrollTo(0, 0);
      }
    },
    showDialog(item, index, cTyp) {
      console.log(item);
      console.log(this.dialog);
      this.dialog = true;
      this.currentItem = item;
      this.currentItemType = cTyp;
      this.currentIndex = index;
    },
    showResetDialog() {
      this.resetdialogIsOpen = true;
    },

    async pushToNextStage() {
      this.$store.commit("setGutachtenDetailOverlay", true);
      // 1. push current data state to backend
      const tmpList = this.getUserPermissions.tabs;
      //console.log(tmpList)
      //get ordernr of an current stage
      let nextStageOrderId = undefined;
      for (let it of tmpList) {
        if (it.step === this.currentStageName) {
          nextStageOrderId = (parseInt(it.id) + 1).toString();
          break;
        }
      }
      let nextStageName = undefined;
      for (let it of tmpList) {
        if (it.id.toString() === nextStageOrderId) {
          nextStageName = it.step;
          break;
        }
      }
      //console.log("nextstage name: "+nextStageName)
      await this.$store.dispatch("pushDataToNewStage", {
        id: this.auftrag.metadata.id,
        stage: nextStageName,
        data: this.auftrag
      });
      this.$emit("reload-gutachten-list");
      this.$emit("update-auftrag-state", false);
      this.$store.commit("setGutachtenDetailOverlay", false);
    },
    closeDialog(value) {
      //console.log("close received ");
      this.dialog = false;
      //console.log("type:"+this.currentItemType);
      //console.log("item:"+this.currentItem+" | "+this.currentIndex);

      if (this.currentItemType == "simple" && value !== undefined) {
        this.currentItem[this.currentIndex] = value;
      } else if (value !== undefined) {
        this.currentItem[this.currentItem.length - 1][
          this.currentIndex
        ] = value;
      }
      //Todo: Toast einbauen mit Success Mitteilung
      this.currentItem = null;
      this.currentIndex = null;
      this.currentItemType = null;
    },
    removeRow(item, index) {
      console.log(item, index);
      item.splice(index, 1);
    },
    hideIcon(index) {
      if (index > 0) {
        this.showDeleteIcon = true;
        return true;
      } else {
        this.showDeleteIcon = false;
        return false;
      }
    },
    async openDocument(objectRef) {
      console.log("get direct doc link");
      const link = await downloadLinkSigner.getSignedDownloadLink(
        this.$store,
        objectRef.bucket,
        objectRef.key
      );
      window.open(link, "_blank");
    },
    showRemovalDialog(name, index) {
      this.deleteDialogName = name;
      this.deleteDialogIndex = index;
      this.confirmDeleteDialog = true;
    },
    hideRemovalDialog() {
      this.deleteDialogIndex = null;
      this.deleteDialogName = null;
      this.confirmDeleteDialog = false;
    },
    confirmRemoval() {
      this.removeWerkstattTab(this.deleteDialogIndex);
      this.confirmDeleteDialog = false;

      this.snackbar = true;
      this.snackbartext = this.deleteDialogName + " gelöscht.";
      this.snackColor = "success";

      //Snackbar
    }
  },
  data() {
    //todo: remove temp data on rollout
    return {
      // Datepicker Variablen
      menu_ez: false,
      menu_schadentag: false,
      currentDate: new Date().toISOString().split("T")[0],

      showDeleteIcon: true,
      currentStageName: this.currentStage,
      currentItem: null,
      currentItemType: null,
      currentIndex: null,
      resetdialogIsOpen: false,
      fourthSummaryFieldRuleValue: false,
      firstSummaryFieldRuleValue: false,
      dialog: false,
      dialogText: "asd",

      confirmDeleteDialog: false,
      deleteDialogName: null,
      deleteDialogIndex: null,

      snackbar: null,
      snackbartext: null,
      snackColor: null,
      timeout: null,

      tab: null,
      tabsLength: 1,

      checkSumsIncorrect: {
        status: false,
        columns: {
          "1": false,
          "4": false
        }
      },
      auftrag: {
        // kopfdaten: {
        //   fzg_stammdaten: {
        //     title: "Fzg. Stammdaten",
        //     content: {
        //       hersteller: {title: "Hersteller", value: "MERCEDES BENZ"},
        //       modell: {title: "Modell: ", value: "B -KLASSE"},
        //       ez: {title: "EZ: ", value: "30.06.2015"},
        //       kennzeichen: {title: "Kennzeichen: ", value: "DA-PA 174"},
        //       schadentag: {title: "Schadentag: ", value: "undefined"},
        //       fahrzeugalter: {title: "Fzg. Alter: ", value: "undefined"}
        //     }
        //   },
        //   pruefbericht: {
        //     title: "Prüfbericht / Vers.",
        //     download: {
        //       bucket: "unfallgutachen24-input-documents-euwest1-dev",
        //       key:
        //           "02-03-2021/41c6ae62-1091-4319-900a-e6554730fa19/input/allianz__prüfbericht1.pdf",
        //       filename: "allianz__prüfbericht1.pdf"
        //     },
        //     download_link:
        //         "https://www.dekra.de/media/dekra-muster-zustandsbericht.pdf",
        //     content: {
        //       versicherer: {title: "Versicherer", value: "undefined"},
        //       schadennummer: {
        //         title: "Schadennummer: ",
        //         value: "AS 9921600692"
        //       },
        //       ersteller: {title: "Ersteller: ", value: "undefined"},
        //       bearbeiter: {title: "Bearbeiter:", value: "undefined"},
        //       datum: {title: "Datum: ", value: "undefined"},
        //       p_nr: {title: "P-Nr: ", value: "undefined"}
        //     }
        //   },
        //   referenzwerkstatt: {
        //     title: "Verwendete Referenz-Firma",
        //     recognition_key: "referenz_werkstatt_objects",
        //     type: "object_array",
        //     recognition_successfull: true,
        //     content: [
        //       {
        //         metadata: {
        //           name: "Verwendete Referenz-Firma",
        //           strasse: "Marburger Str. 28",
        //           plz: "64289",
        //           ort: "Darmstadt",
        //           telefon: "06151/153590",
        //           entfernung: "5 km",
        //           reale_entfernung: "undefined"
        //         },
        //         additional_information: {
        //           "1": "Verbringungskosten: 0,00 €",
        //           "2": "UPE-Aufschlag (0%): 0,00 €",
        //           "3":
        //               "Eurogarant - Fachbetrieb, Innovation Group - Fachbetrieb, ZKF - Fachbetrieb, zertifizierter KFZ - Fachbetrieb"
        //         },
        //         prices: {
        //           "1": {
        //             name: "Lohnfaktoren / Std.",
        //             headers: ["Leistung", "Preis"],
        //             rows: [
        //               ["Mechanik", "112,00 €"],
        //               ["Elektrik", "112,00 €"],
        //               ["Karosserie", "112,00 €"],
        //               ["Lack inkl. Lackmaterial", "164,70 €"]
        //             ]
        //           }
        //         }
        //       },
        //       {
        //         metadata: {
        //           name: "Verwendete Referenz-Firma",
        //           strasse: "Marburger Str. 28",
        //           plz: "64289",
        //           ort: "Darmstadt",
        //           telefon: "06151/153590",
        //           entfernung: "5 km"
        //         },
        //         additional_information: {
        //           "1": "Verbringungskosten: 0,00 €",
        //           "2": "UPE-Aufschlag (0%): 0,00 €",
        //           "3":
        //               "Eurogarant - Fachbetrieb, Innovation Group - Fachbetrieb, ZKF - Fachbetrieb, zertifizierter KFZ - Fachbetrieb"
        //         },
        //         prices: {
        //           "1": {
        //             name: "Lohnfaktoren / Std.",
        //             headers: ["Leistung", "Preis"],
        //             rows: [
        //               ["Mechanik", "112,00 €"],
        //               ["Elektrik", "112,00 €"],
        //               ["Karosserie", "112,00 €"],
        //               ["Lack inkl. Lackmaterial", "164,70 €"]
        //             ]
        //           }
        //         }
        //       }
        //     ]
        //   },
        //   gutachten: {
        //     title: "Gutachten",
        //     download: {
        //       bucket: "unfallgutachen24-input-documents-euwest1-dev",
        //       key:
        //           "02-03-2021/41c6ae62-1091-4319-900a-e6554730fa19/input/gutachten_1_malik.pdf",
        //       filename: "gutachten_1_malik.pdf"
        //     },
        //     download_link:
        //         "https://www.dekra.de/media/dekra-muster-zustandsbericht.pdf",
        //     content: {
        //       sachverstaendiger: {
        //         title: "Sachverständiger",
        //         value: "undefined"
        //       },
        //       gutachtennummer: {title: "Gutachten-Nr.: ", value: "undefined"},
        //       gutachtendatum: {title: "Gutachtendatum: ", value: "undefined"}
        //     }
        //   },
        //   kunde: {
        //     title: "Kunde",
        //     content: {
        //       anrede: {title: "Anrede", value: "Herr"},
        //       name: {title: "Name", value: "Malik Dakdaki"},
        //       strasse: {title: "Straße: ", value: "Brunnenstr. 16"},
        //       plz_ort: {
        //         title: "PLZ / Ort: ",
        //         value: "65835 Liederbach am Taunus"
        //       }
        //     }
        //   },
        //   ra: {
        //     title: "RA",
        //     content: {
        //       anrede: {title: "Anrede", value: "Herr"},
        //       name: {title: "Name", value: "Niklas Reimche"},
        //       strasse: {title: "Straße: ", value: "Bungalow 23"},
        //       plz_ort: {title: "PLZ / Ort: ", value: "78554 Bromberg"}
        //     }
        //   }
        // },
        // minderwerte: {
        //   title: "Zusammenfassung",
        //   recognition_key: "pruefung_gutachten_uebersicht",
        //   header: [
        //     "Bezeichnung / Leistung",
        //     "Eingangswert vor Prüfung",
        //     "Technische Prüfung",
        //     "Fiktive Abrechnung",
        //     "Ausgangswert nach Prüfung",
        //     "Finale Gegenprüfung"
        //   ],
        //   rows: [
        //     [
        //       "Arbeitslohn (ohne Verbringungskosten)",
        //       "617,15",
        //       "-17,44",
        //       "-273,04",
        //       "326,67",
        //       "326,67",
        //       [""]
        //     ],
        //     [
        //       "Verbringungskosten",
        //       "0,00",
        //       "0,00",
        //       "0,00",
        //       "0,00",
        //       "0,00",
        //       [""]
        //     ],
        //     [
        //       "Lackierung (inkl. Lackmaterial)",
        //       "585,98",
        //       "-48,83",
        //       "-235,20",
        //       "301,95",
        //       "301,95",
        //       [""]
        //     ],
        //     [
        //       "Ersatzteile",
        //       "869,58",
        //       "-156,34",
        //       "0,00",
        //       "713,24",
        //       "713,24",
        //       [""]
        //     ],
        //     ["Kleinteile", "0,00", "0,00", "0,00", "0,00", "0,00", [""]],
        //     ["Nebenkosten", "61,00", "-61,00", "0,00", "0,00", "0,00", [""]]
        //   ],
        //   summary: [
        //     "Gesamtbetrag",
        //     "2.133,71",
        //     "-283,61",
        //     "-508,24",
        //     "1.341,86",
        //     "1.341,86"
        //   ],
        //   recognition_successfull: true
        // },
        // technische_pruefung: {
        //   title: "Technische Prüfung",
        //   recognition_key: "pruefung_gutachten_uebersicht",
        //   header: [
        //     "Bezeichnung / Leistung",
        //     "Eingangswert vor Prüfung",
        //     "Technische Prüfung",
        //     "Fiktive Abrechnung",
        //     "Ausgangswert nach Prüfung",
        //     "Finale Gegenprüfung"
        //   ],
        //   rows: [
        //     [
        //       "Arbeitslohn (ohne Verbringungskosten)",
        //       "617,15",
        //       "-17,44",
        //       "-273,04",
        //       "326,67",
        //       "326,67",
        //       [""]
        //     ],
        //     [
        //       "Verbringungskosten",
        //       "0,00",
        //       "0,00",
        //       "0,00",
        //       "0,00",
        //       "0,00",
        //       [""]
        //     ],
        //     [
        //       "Lackierung (inkl. Lackmaterial)",
        //       "585,98",
        //       "-48,83",
        //       "-235,20",
        //       "301,95",
        //       "301,95",
        //       [""]
        //     ],
        //     [
        //       "Ersatzteile",
        //       "869,58",
        //       "-156,34",
        //       "0,00",
        //       "713,24",
        //       "713,24",
        //       [""]
        //     ],
        //     ["Kleinteile", "0,00", "0,00", "0,00", "0,00", "0,00", [""]],
        //     ["Nebenkosten", "61,00", "-61,00", "0,00", "0,00", "0,00", [""]]
        //   ],
        //   summary: [
        //     "Gesamtbetrag",
        //     "2.133,71",
        //     "-283,61",
        //     "-508,24",
        //     "1.341,86",
        //     "1.341,86"
        //   ],
        //   recognition_successfull: true
        // },
        // metadata: {
        //   id: "usv9gk64tno8ynut8wjy8lymy3xpas",
        //   auftrag: {typ: "normal", besteller: "kunde"}
        // }
      }
    };
  }
};
</script>

<style>
.mdi.mdi-table-row-remove {
  color: #ff0000;
}

#empty-tablist > div {
  height: auto;
}

.brace-border-div {
  border-color: #64b5f6 !important;
  border-style: dashed none dashed dashed;
  border-width: medium;
  height: 100%;
  max-width: 10px;
  min-width: 10px;
}

.v-main .v-dialog {
  position: absolute;
  bottom: 40%;
  left: 0;
}

.v-main .v-divider {
  margin-top: 20px;
  margin-bottom: 20px;
}

.v-main .v-list-item {
  min-height: 42px;
}

.hint-color.theme--light.v-input input {
  color: red;
}

.hint-color .theme--light.v-messages {
  color: red;
}
</style>
